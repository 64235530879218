<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("projects") }}</h1>
      <br>
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="projects">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div v-if="role ==='admin'"  class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ projects.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : projects.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="name">{{ $t("name") }}</vs-th>
          <vs-th sort-key="description">{{ $t("description") }}</vs-th>
          <vs-th sort-key="country">{{ $t("country") }}</vs-th>
          <vs-th sort-key="pessNokia">{{ $t("pessNokia") }}</vs-th>
          <vs-th sort-key="rom">{{ $t("rom") }}</vs-th>
          <vs-th sort-key="qhsNokia">{{ $t("qhsNokia") }}</vs-th>
          <vs-th sort-key="leadProject">{{ $t("leadProject") }}</vs-th>
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.name}}</p>
            </vs-td>
         
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.description}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.country !== null ? tr.country.name : '-'}}</p>
            </vs-td>
            <vs-td>
              <p v-if="!tr.pessNokia" class="product-name font-medium truncate">{{ '-' }}</p>
              <li v-if="tr.pessNokia" v-for="pessNokia in tr.pessNokia" :key="pessNokia" class="product-name font-medium truncate">{{pessNokia.last_name+' '+pessNokia.first_name}}</li>
            </vs-td> 
           
            <vs-td>
              <p v-if="!tr.rom" class="product-name font-medium truncate">{{ '-' }}</p>
              <li v-if="tr.rom" v-for="rom in tr.rom" :key="rom" class="product-name font-medium truncate">{{rom.last_name+' '+rom.first_name}}</li>
            </vs-td>

            <vs-td>            
              <p v-if="!tr.qhsNokia" class="product-name font-medium truncate">{{ '-' }}</p>
              <li v-if="tr.qhsNokia" v-for="qhsNokia in tr.qhsNokia" :key="qhsNokia" class="product-name font-medium truncate">{{qhsNokia.last_name+' '+qhsNokia.first_name}}</li>
            </vs-td>

            <vs-td>
              <p v-if="!tr.leadProject" class="product-name font-medium truncate">{{ '-' }}</p>
              <li v-if="tr.leadProject" v-for="leadProject in tr.leadProject" :key="leadProject" class="product-name font-medium truncate">{{leadProject.last_name+' '+leadProject.first_name}}</li>
            </vs-td>
            
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vx-tooltip :text="$t('Voir')" color="success">
                  <feather-icon
                    style="color: #00a00d"
                    icon="EyeIcon"
                    svgClasses="w-5 h-5 hover:text-success stroke-current"
                    class="mr-2"
                    @click="Show_data(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip :text="$t('Modifier')" color="warning">
                  <feather-icon
                    style="color: #FF9F43"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-warning stroke-current"
                    class="mr-2"
                    @click="Edit(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip v-if="role ==='admin'" :text="$t('Supprimer')" color="danger">
                  <feather-icon
                    @click="deleteData(tr.id)"
                    style="color: red"
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="mr-2"
                  />
                </vx-tooltip>
               <!--  <vx-tooltip v-if="role !=='managerSubcontractor'" text="Valider le personnel" color="success">
                <feather-icon @click="comfirmation(tr.id)" style="color: #06ac0e" icon="CheckCircleIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip v-if="role !=='managerSubcontractor'" text="Rejeter le personnel" color="danger">
                <feather-icon @click="rejet_Pop(tr.id)" style="color: #ff6141" icon="XCircleIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip v-if="tr.activated === 0 && role !=='managerSubcontractor' " text="Rapport" color="dark">
                <feather-icon @click="rapport(tr.satffComments)" style="color: black" icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-dark stroke-current" class="mr-2"/>
              </vx-tooltip> -->
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-popup
      v-if="activePrompt1" 
      fullscreen 
      class="calendar-event-dialog"
      :title="$t('ajouter_projet')"
      :active.sync="activePrompt1">
      <div class="clearfix">
      <div class="vx-row">

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
          <p>{{ $t("name") }}<b style="color: #ff6141">*</b></p>
          <vs-input 
            :disabled= "statusRole"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{$t("name_error")}}</span>
  
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
            <p>{{ $t("description") }}<b style="color: #ff6141">*</b></p>
          <vs-input
            :disabled= "statusRole"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="description"
            v-model="description"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ $t("description_error") }}</span>
  
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
            <p> {{ $t("country") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
                v-validate="'required'"
                name="country"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="country"
                :options="countries">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('country')">{{$t("country_error") }}</span>
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
          
          
            <p> {{ $t("pessNokia") }} <b style="color: #ff6141" >*</b> </p>
                <v-select
                  :disabled= "statusRole"
                  multiple
                  v-validate="'required'"
                  name="pessNokia"
                  :reduce="rep => rep.id"
                  class="w-full" label="last_name"
                  v-model="pessNokia"
                  :options="pessNokias">
                </v-select>
              <span class="text-danger text-sm" v-show="errors.has('pessNokia')">{{ $t("pessNokia_error") }}</span>
              <p> {{ $t("rom") }} <b style="color: #ff6141" >*</b> </p>
                <v-select
                :disabled= "statusRole"
                  multiple
                  v-validate="'required'"
                  name="rom"
                  :reduce="rep => rep.id"
                  class="w-full" label="last_name"
                  v-model="rom"
                  :options="roms">
                </v-select>
              <span class="text-danger text-sm" v-show="errors.has('rom')">{{$t("rom_error") }}</span>
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
            
            
            <p> {{ $t("qhsNokia") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
              :disabled= "statusRole"
                multiple
                v-validate="'required'"
                name="qhsNokia"
                :reduce="rep => rep.id"
                class="w-full" label="last_name"
                v-model="qhsNokia"
                :options="qhsNokias">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('qhsNokia')">{{$t("qhsNokia_error") }}</span>
            
            <p> {{ $t("leadProject") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
              :disabled= "statusRole"
                multiple
                v-validate="'required'"
                name="leadProject"
                :reduce="rep => rep.id"
                class="w-full" label="last_name"
                v-model="leadProject"
                :options="leadProjects">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('leadProject')">{{$t("leadProject_error") }}</span>
            
           
          </div>

      <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{$t("creation_bon_com") }}</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
      <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="vx-row">
      
        <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
            <label class="text-sm opacity-75 mp-2">{{ $t("purchase") }}<b style="color: #ff6141" >*</b></label>
            <vs-input
            name="purchase"
            v-model="purchaseOrderNumber"
            class="w-full"
          />

              <div  class="vx-col md:w-full sm:w-full w-full ">
                <div class="flex flex-wrap justify-between mt-5 ">
                    <vs-button v-if="!update_produitChek_purchaseOrderNumber" :disabled="!validate_purchaseOrderNumber" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_purchases">{{ $t("Ajouter") }}</vs-button>
                    
                    <vs-button v-if="update_produitChek_purchaseOrderNumber" :disabled="!validate_purchaseOrderNumber" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_purchases">{{ $t("Modifier") }}</vs-button>

                    <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_purchases">{{ $t("Annuler") }}</vs-button>
              
                </div>
              </div>
        </div>
        
        <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
            <label class="text-sm opacity-75 mp-2">{{ $t("orderOfWorks") }}<b style="color: #ff6141" >*</b></label>
                <vs-input
                name="orderOfWorks"
                v-model="orderNumberOfWork"
                class="w-full"
              />
              <div  class="vx-col md:w-full sm:w-full w-full ">
                <div class="flex flex-wrap justify-between mt-5 ">
                    <vs-button v-if="!update_produitChek_orderNumberOfWork" :disabled="!validate_orderNumberOfWork" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_orderNumberOfWorks">{{ $t("Ajouter") }}</vs-button>
                    
                    <vs-button v-if="update_produitChek_orderNumberOfWork" :disabled="!validate_orderNumberOfWork" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_orderNumberOfWorks">{{ $t("Modifier") }}</vs-button>

                    <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_orderNumberOfWork">{{ $t("Annuler") }}</vs-button>
                  
                  </div>
         </div>

        </div>
       
        </div>
      </div>
     
    </div>
    <div class="vx-row ">
      <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
        <vs-table stripe max-items="4" class="w-full  " pagination search :data="purchases_liste">
        <template slot="header">
          <h3>
            {{ $t("bon_comds") }}
          </h3>
        </template>
        <template slot="thead">
          <vs-th hidden  >N°</vs-th>
          <vs-th> {{ $t("bon_comd") }}</vs-th>
          <vs-th>Actions</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          
            <vs-td hidden :data="data[indextr]">
              {{data[indextr].id}}
            </vs-td>
            <vs-td :data="data[indextr]">
              {{data[indextr].purchaseOrderNumber}}
            </vs-td>
            
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vs-button @click.stop="update_purchases(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
                
                <vs-button @click.stop="delete_purchases(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
            
              </div>
            </vs-td>
          </vs-tr>
        </template>
        </vs-table>
      </div>
      <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
        <vs-table stripe max-items="4" class="w-full  " pagination search :data="orderOfWorks_liste">
        <template slot="header">
          <h3>
            {{ $t("ords_travail") }}
          </h3>
        </template>
        <template slot="thead">
          <vs-th hidden  >N°</vs-th>
          <vs-th> {{ $t("ord_travail") }} </vs-th>
          <vs-th>Actions</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          
            <vs-td hidden :data="data[indextr].id">
              {{data[indextr].id}}
            </vs-td>
            <vs-td :data="data[indextr]">
              {{data[indextr].orderNumberOfWork}}
            </vs-td>
            
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vs-button @click.stop="update_orderNumberOfWork(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
                
                <vs-button @click.stop="delete_orderNumberOfWork(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
            
              </div>
            </vs-td>
          </vs-tr>
        </template>
        </vs-table>
      </div>
    </div>
      </vx-card>
      <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{ $t("info_assignation") }}</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
      <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="vx-row">
          <div class="vx-col md:w-1/5 sm:w-1/5 w-full">
              <label class="text-sm opacity-75 mp-2">{{ $t("site") }} <b style="color: #ff6141" >*</b></label>
              <v-select
                  name="site_tab"
                  :reduce="rep => rep"
                  class="w-full" label="name"
                  v-model="site_tab"
                  :options="sites_liste">
                </v-select>
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("sous_traitant") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                name="company_tab"
                :reduce="rep => rep"
                class="w-full" label="name"
                v-model="company_tab"
                :options="companies_liste">
              </v-select>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("purchase") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                name="company_tab"
                :reduce="rep => rep"
                class="w-full" label="purchaseOrderNumber"
                v-model="purchases_tab"
                :options="purchases_liste">
              </v-select>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("orderOfWorks") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                multiple
                name="company_tab"
                :reduce="rep => rep"
                class="w-full" label="orderNumberOfWork"
                v-model="orderOfWorks_tab"
                :options="orderOfWorks_liste">
              </v-select>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("tasks") }} <b style="color: #ff6141" ></b></label>
            <v-select
                multiple
                name="task_tab"
                :reduce="rep => rep"
                class="w-full" label="name"
                v-model="task_tab"
                :options="tasks_liste">
              </v-select>
        </div>
        </div>
      </div>
     

      <div  class="vx-col md:w-full sm:w-full w-full ">
          <div class="flex flex-wrap justify-between mt-5 ">
              <vs-button v-if="!update_produitChek" :disabled="!validate_" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">{{ $t("Ajouter") }}</vs-button>
              
              <vs-button v-if="update_produitChek" :disabled="!validate_" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">{{ $t("Modifier") }}</vs-button>

              <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">{{ $t("Annuler") }}</vs-button>
            
            </div>
      </div>
    </div>
    <div class="vx-row ">
      <vs-table stripe max-items="4" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
        <h3>
          {{ $t("assignations") }}
        </h3>
      </template>
      <template slot="thead">
        <vs-th hidden  >N°</vs-th>
        <vs-th>{{ $t("site") }}</vs-th>
        <vs-th>{{ $t("sous_traitant") }}</vs-th>
        <vs-th>{{ $t("bon_comd") }}</vs-th>
        <vs-th>{{ $t("ords_travail") }}</vs-th>
        <vs-th>{{ $t("tasks") }}</vs-th>
        <vs-th>Actions</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
        
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].sites">
            {{data[indextr].sites.name}}
          </vs-td>

          <vs-td :data="data[indextr].compagnies">
             {{data[indextr].compagnies.name}}
           </vs-td>

           <vs-td :data="data[indextr].purchase">
             {{data[indextr].purchase.purchaseOrderNumber}}
           </vs-td>

           <vs-td :data="data[indextr].orderOfWorks">
            <li v-if="data[indextr].orderOfWorks" v-for="orderOfWorks in data[indextr].orderOfWorks" :key="orderOfWorks" >{{orderOfWorks.orderNumberOfWork }}</li>
             <li v-if="!data[indextr].orderOfWorks" >{{ '-' }}</li>
          </vs-td>

          <vs-td :data="data[indextr].tasks">
            <li v-if="data[indextr].tasks" v-for="task in data[indextr].tasks" :key="task" >{{task.name }}</li>
             <li v-if="!data[indextr].tasks" >{{ '-' }}</li>
          </vs-td>
          
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
              
              <vs-button @click.stop="deleteProduit(data[indextr].id, data[indextr].file)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
          
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
      </vx-card>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="formation_validate()" >{{ $t("Soumettre") }}</vs-button>
     </div>
      </vs-popup>

      <vs-popup
      v-if="activePrompt2" 
      fullscreen 
      class="calendar-event-dialog"
      :title="$t('modifier_projet')"
      :active.sync="activePrompt2">
      <div class="clearfix">
      <div class="vx-row">

        <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
          <p>{{ $t("name") }}<b style="color: #ff6141">*</b></p>
          <vs-input 
            :disabled= "statusRole"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{$t("name_error")}}</span>
  
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
            <p>{{ $t("description") }}<b style="color: #ff6141">*</b></p>
          <vs-input
            :disabled= "statusRole"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="description"
            v-model="description"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ $t("description_error") }}</span>
  
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
            <p> {{ $t("country") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
                v-validate="'required'"
                name="country"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="country"
                :options="countries">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('country')">{{$t("country_error") }}</span>
          </div>
        
          <div v-if="role ==='admin'" class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
          
          
            <p> {{ $t("pessNokia") }} <b style="color: #ff6141" >*</b> </p>
                <v-select
                  :disabled= "statusRole"
                  multiple
                  v-validate="'required'"
                  name="pessNokia"
                  :reduce="rep => rep.id"
                  class="w-full" label="last_name"
                  v-model="pessNokia"
                  :options="pessNokias">
                </v-select>
              <span class="text-danger text-sm" v-show="errors.has('pessNokia')">{{ $t("pessNokia_error") }}</span>
              <p> {{ $t("rom") }} <b style="color: #ff6141" >*</b> </p>
                <v-select
                :disabled= "statusRole"
                  multiple
                  v-validate="'required'"
                  name="rom"
                  :reduce="rep => rep.id"
                  class="w-full" label="last_name"
                  v-model="rom"
                  :options="roms">
                </v-select>
              <span class="text-danger text-sm" v-show="errors.has('rom')">{{$t("rom_error") }}</span>
          </div>
  
          <div v-if="role ==='admin'" class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
            
            
            <p> {{ $t("qhsNokia") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
              :disabled= "statusRole"
                multiple
                v-validate="'required'"
                name="qhsNokia"
                :reduce="rep => rep.id"
                class="w-full" label="last_name"
                v-model="qhsNokia"
                :options="qhsNokias">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('qhsNokia')">{{$t("qhsNokia_error") }}</span>
            
            <p> {{ $t("leadProject") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
              :disabled= "statusRole"
                multiple
                v-validate="'required'"
                name="leadProject"
                :reduce="rep => rep.id"
                class="w-full" label="last_name"
                v-model="leadProject"
                :options="leadProjects">
              </v-select>
            <span class="text-danger text-sm" v-show="errors.has('leadProject')">{{$t("leadProject_error") }}</span>
            
           
          </div>

      <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{$t("creation_bon_com") }}</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
      <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="vx-row">
      
        <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
            <label class="text-sm opacity-75 mp-2">{{ $t("purchase") }}<b style="color: #ff6141" >*</b></label>
            <vs-input
            name="purchase"
            v-model="purchaseOrderNumber"
            class="w-full"
          />

              <div  class="vx-col md:w-full sm:w-full w-full ">
                <div class="flex flex-wrap justify-between mt-5 ">
                    <vs-button v-if="!update_produitChek_purchaseOrderNumber" :disabled="!validate_purchaseOrderNumber" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_purchases">{{ $t("Ajouter") }}</vs-button>
                    
                    <vs-button v-if="update_produitChek_purchaseOrderNumber" :disabled="!validate_purchaseOrderNumber" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_purchases">{{ $t("Modifier") }}</vs-button>

                    <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_purchases">{{ $t("Annuler") }}</vs-button>
              
                </div>
              </div>
        </div>
        
        <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
            <label class="text-sm opacity-75 mp-2">{{ $t("orderOfWorks") }}<b style="color: #ff6141" >*</b></label>
                <vs-input
                name="orderOfWorks"
                v-model="orderNumberOfWork"
                class="w-full"
              />
          <div  class="vx-col md:w-full sm:w-full w-full ">
                  <div class="flex flex-wrap justify-between mt-5 ">
                      <vs-button v-if="!update_produitChek_orderNumberOfWork" :disabled="!validate_orderNumberOfWork" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_orderNumberOfWorks">{{ $t("Ajouter") }}</vs-button>
                      
                      <vs-button v-if="update_produitChek_orderNumberOfWork" :disabled="!validate_orderNumberOfWork" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_orderNumberOfWorks">{{ $t("Modifier") }}</vs-button>

                      <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_orderNumberOfWork">{{ $t("Annuler") }}</vs-button>
                    
                  </div>
          </div>

        </div>
       
        </div>
      </div>
     
    </div>
    <div class="vx-row ">
      <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
        <vs-table stripe max-items="4" class="w-full  " pagination search :data="purchases_liste">
          <template slot="header">
          <h3>
            {{ $t("bon_comds") }}
          </h3>
        </template>
        <template slot="thead">
          <vs-th hidden  >N°</vs-th>
          <vs-th> {{ $t("bon_comd") }}</vs-th>
          <vs-th>Actions</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          
            <vs-td hidden :data="data[indextr]">
              {{data[indextr].id}}
            </vs-td>
            <vs-td :data="data[indextr]">
              {{data[indextr].purchaseOrderNumber}}
            </vs-td>
            
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vs-button @click.stop="update_purchases(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
                
                <vs-button @click.stop="delete_purchases(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
            
              </div>
            </vs-td>
          </vs-tr>
        </template>
        </vs-table>
      </div>
      <div class="vx-col md:w-1/2 sm:w-1/2 w-1/2">
        <vs-table stripe max-items="4" class="w-full  " pagination search :data="orderOfWorks_liste">
          <template slot="header">
          <h3>
            {{ $t("ords_travail") }}
          </h3>
        </template>
        <template slot="thead">
          <vs-th hidden  >N°</vs-th>
          <vs-th> {{ $t("ord_travail") }} </vs-th>
          <vs-th>Actions</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          
            <vs-td hidden :data="data[indextr].id">
              {{data[indextr].id}}
            </vs-td>
            <vs-td :data="data[indextr]">
              {{data[indextr].orderNumberOfWork}}
            </vs-td>
            
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vs-button @click.stop="update_orderNumberOfWork(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
                
                <vs-button @click.stop="delete_orderNumberOfWork(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
            
              </div>
            </vs-td>
          </vs-tr>
        </template>
        </vs-table>
      </div>
    </div>
      </vx-card>
      <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{ $t("info_assignation") }}</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
      <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="vx-row">
          <div class="vx-col md:w-1/5 sm:w-1/5 w-full">
              <label class="text-sm opacity-75 mp-2">{{ $t("site") }}<b style="color: #ff6141" >*</b></label>
              <v-select
                  name="site_tab"
                  :reduce="rep => rep"
                  class="w-full" label="name"
                  v-model="site_tab"
                  :options="sites_liste">
                </v-select>
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("sous_traitant") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                name="company_tab"
                :reduce="rep => rep"
                class="w-full" label="name"
                v-model="company_tab"
                :options="companies_liste">
              </v-select>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("purchase") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                name="company_tab"
                :reduce="rep => rep"
                class="w-full" label="purchaseOrderNumber"
                v-model="purchases_tab"
                :options="purchases_liste">
              </v-select>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("orderOfWorks") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                multiple
                name="company_tab"
                :reduce="rep => rep"
                class="w-full" label="orderNumberOfWork"
                v-model="orderOfWorks_tab"
                :options="orderOfWorks_liste">
              </v-select>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/5 w-1/5">
            <label class="text-sm opacity-75 mp-2">{{ $t("tasks") }} <b style="color: #ff6141" ></b></label>
            <v-select
                multiple
                name="task_tab"
                :reduce="rep => rep"
                class="w-full" label="name"
                v-model="task_tab"
                :options="tasks_liste">
              </v-select>
        </div>
        </div>
      </div>
     

      <div  class="vx-col md:w-full sm:w-full w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
                  <vs-button v-if="!update_produitChek" :disabled="!validate_" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">{{ $t("Ajouter") }}</vs-button>
                  
                  <vs-button v-if="update_produitChek" :disabled="!validate_" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">{{ $t("Modifier") }}</vs-button>

                  <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">{{ $t("Annuler") }}</vs-button>
                
          </div>
      </div>
   
    </div>
    <div class="vx-row ">
      <vs-table stripe max-items="4" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
        <h3>
          {{ $t("assignations") }}
        </h3>
      </template>
      <template slot="thead">
        <vs-th hidden  >N°</vs-th>
        <vs-th>{{ $t("site") }}</vs-th>
        <vs-th>{{ $t("sous_traitant") }}</vs-th>
        <vs-th>{{ $t("bon_comd") }}</vs-th>
        <vs-th>{{ $t("ords_travail") }}</vs-th>
        <vs-th>{{ $t("tasks") }}</vs-th>
        <vs-th>Actions</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
        
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].sites">
            {{data[indextr].sites.name}}
          </vs-td>

          <vs-td :data="data[indextr].compagnies">
             {{data[indextr].compagnies.name}}
           </vs-td>

           <vs-td :data="data[indextr].purchase">
             {{data[indextr].purchase.purchaseOrderNumber}}
           </vs-td>

           <vs-td :data="data[indextr].orderOfWorks">
            <li v-if="data[indextr].orderOfWorks" v-for="orderOfWorks in data[indextr].orderOfWorks" :key="orderOfWorks" >{{orderOfWorks.orderNumberOfWork }}</li>
             <li v-if="!data[indextr].orderOfWorks" >{{ '-' }}</li>
          </vs-td>

          <vs-td :data="data[indextr].tasks">
            <li v-if="data[indextr].tasks" v-for="task in data[indextr].tasks" :key="task" >{{task.name }}</li>
             <li v-if="!data[indextr].tasks" >{{ '-' }}</li>
          </vs-td>
          
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
              
              <vs-button @click.stop="deleteProduit(data[indextr].id, data[indextr].file)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
          
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
      </vx-card>


      </div>
      <vs-button color="success"  class="float-right mt-4" @click="formation_validate()" >{{ $t("Soumettre") }}</vs-button>
     </div>
      </vs-popup>

      <vs-popup
      v-if="activePrompt3" 
      fullscreen 
      class="calendar-event-dialog"
      :title="$t('details_projet')"
      :active.sync="activePrompt3">
      <div class="clearfix">
      <div class="vx-row">

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
          <p>{{ $t("name") }}<b style="color: #ff6141">*</b></p>
          <vs-input 
            disabled
            data-vv-validate-on="blur"
            name="name"
            v-model="name"
            class="w-full"
          />
  
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
            <p>{{ $t("description") }}<b style="color: #ff6141">*</b></p>
          <vs-input
            disabled
            data-vv-validate-on="blur"
            name="description"
            v-model="description"
            class="w-full"
          />
  
          </div>

          <div v-if="role ==='admin'" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
            <p> {{ $t("country") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
                disabled
                name="country"
                :reduce="rep => rep.id"
                class="w-full" label="name"
                v-model="country"
                :options="countries">
              </v-select>
          </div>
        
          <div v-if="role ==='admin'" class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
          
          
            <p> {{ $t("pessNokia") }} <b style="color: #ff6141" >*</b> </p>
                <v-select
                  disabled
                  multiple
                  name="pessNokia"
                  :reduce="rep => rep.id"
                  class="w-full" label="last_name"
                  v-model="pessNokia"
                  :options="pessNokias">
                </v-select>
              <p> {{ $t("rom") }} <b style="color: #ff6141" >*</b> </p>
                <v-select
                  disabled
                  multiple
                  name="rom"
                  :reduce="rep => rep.id"
                  class="w-full" label="last_name"
                  v-model="rom"
                  :options="roms">
                </v-select>
          </div>
  
          <div v-if="role ==='admin'" class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
            
            
            <p> {{ $t("qhsNokia") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
                disabled
                multiple
                name="qhsNokia"
                :reduce="rep => rep.id"
                class="w-full" label="last_name"
                v-model="qhsNokia"
                :options="qhsNokias">
              </v-select>
            
            <p> {{ $t("leadProject") }} <b style="color: #ff6141" >*</b> </p>
              <v-select
               disabled
                multiple
                name="leadProject"
                :reduce="rep => rep.id"
                class="w-full" label="last_name"
                v-model="leadProject"
                :options="leadProjects">
              </v-select>
            
           
          </div>

      <vx-card class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none"> {{ $t("info_assignation") }}</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
    
    <div class="vx-row ">
      <vs-table stripe max-items="4" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
        <h3>
          {{ $t("assignations") }}       
         </h3>
      </template>
      <template slot="thead">
        <vs-th hidden  >N°</vs-th>
        <vs-th>{{ $t("site") }}</vs-th>
        <vs-th>{{ $t("sous_traitant") }}</vs-th>
        <vs-th>{{ $t("bon_comd") }}</vs-th>
        <vs-th>{{ $t("ords_travail") }}</vs-th>
        <vs-th>{{ $t("tasks") }}</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
        
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].sites">
            {{data[indextr].sites.name}}
          </vs-td>

          <vs-td :data="data[indextr].compagnies">
             {{data[indextr].compagnies.name}}
           </vs-td>

           <vs-td :data="data[indextr].purchase">
             {{data[indextr].purchase.purchaseOrderNumber}}
           </vs-td>

           <vs-td :data="data[indextr].orderOfWorks">
            <li v-if="data[indextr].orderOfWorks" v-for="orderOfWorks in data[indextr].orderOfWorks" :key="orderOfWorks" >{{orderOfWorks.orderNumberOfWork }}</li>
             <li v-if="!data[indextr].orderOfWorks" >{{ '-' }}</li>
          </vs-td>

          <vs-td :data="data[indextr].tasks">
            <li v-if="data[indextr].tasks" v-for="task in data[indextr].tasks" :key="task" >{{task.name }}</li>
             <li v-if="!data[indextr].tasks" >{{ '-' }}</li>
          </vs-td>
          
          
        </vs-tr>
      </template>
    </vs-table>
    </div>
      </vx-card>
      </div>
      <vs-button color="primary"  class="float-right mt-4" @click="quit()" >{{ $t("Quit") }}</vs-button>
     </div>
      </vs-popup>
    </div>
  </template>
<script>

export default {
 
  data () {
    return {
      countries:[],
      country:'',
      purchases_tab:'',
      orderOfWorks_tab:[],
      sites:[],
      compagnies:[],
      tasks:[],
      role:'',
      statusRole:false,
      site_tab:'',
      company_tab:'',
      task_tab:[],
      
      sites_liste:'',
      companies_liste:'',
      tasks_liste:[],

      id_update :'',

      activePromptAssignation:false,
      activePrompt1:false,
      activePrompt2:false,
      activePrompt3:false,
      activePrompt5:false,
      activePrompt4:false,
    
      projects: [],
      roms: [],
      qhsNokias: [],
      leadProjects: [],
      pessNokias:[],
      
      qhsNokia:[],
      pessNokia:[],
      rom: [],
      leadProject: [],

     
      id:'',
      itemIndexProduit:-1,
      itemIndexCompagnie:-1,
      companyObjectResult :'',
      update_produitChek_purchaseOrderNumber : false,
      update_produitChek_orderNumberOfWork : false,
      update_produitChek : false,
      id_produit:1,
      produits_computed:[],
      produit_submit:[],
     
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      purchases_liste:[],
      orderOfWorks_liste:[],
      id_purchaseOrderNumber:'',
      id_orderNumberOfWork:'',
      purchaseOrderNumber:'',
      orderNumberOfWork:''

    }
  },
  watch:{
    company_tab (value) {
      if (value) {
        this.valeur = value
      } else {
        this.company_tab = ''
      }
    },
    site_tab (value) {
      if (value) {
        this.valeur = value
      } else {
        this.site_tab = ''
      }
    },
    purchases_tab (value) {
      if (value) {
        this.valeur = value
      } else {
        this.purchases_tab = ''
      }
    },
    orderOfWorks_tab (value) {
      if (value) {
        this.valeur = value
      } else {
        this.orderOfWorks_tab = []
      }
    }

  },
  computed: {
    validate_purchaseOrderNumber () {
      if ((this.purchaseOrderNumber !== '')) return true
    },
    validate_orderNumberOfWork () {
      if ((this.orderNumberOfWork !== '')) return true
    },
    validate_ () {
      if ((this.site_tab !== '' && this.company_tab !== '' && this.purchases_tab !== '' && this.orderOfWorks_tab.length !== 0)) return true
    },
    validateCompany_tab () {
      if ((this.company_tab !== '')) return true
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.projects.length
    }
  },
  methods: {  
    resetData_orderNumberOfWork () {
      this.update_produitChek_orderNumberOfWork = false
      this.id_orderNumberOfWork = ''
      this.orderNumberOfWork = ''
    },
    resetData_purchases () {
      this.id_purchaseOrderNumber = ''
      this.purchaseOrderNumber = ''
      this.update_produitChek_purchaseOrderNumber = false
      
    },
    delete_orderNumberOfWork (id) {
      this.$vs.loading()
      this.$http.delete(`orderOfWorks/${id}/`)
        .then(response => {
          this.getAllOrderOfWorks()
          window.getPrendTaCom.success(this.$t('SuppressionorderOfWork'), response)
        })
        .catch(() => {
          window.getPrendTaCom.error({ message: this.$t('Suppression') })
        })
  
    },
    delete_purchases (id) {
      this.$vs.loading()
      this.$http.delete(`purchases/${id}/`)
        .then(response => {
          this.getAllPurchases()
          window.getPrendTaCom.success(this.$t('Suppressionpurchases'), response)
        })
        .catch(() => {
          window.getPrendTaCom.error({ message: this.$t('Suppression') })
        })
  
    },
    update_orderNumberOfWork (tr) {
      this.orderNumberOfWork = tr.orderNumberOfWork
      this.id_orderNumberOfWork = tr.id
      this.update_produitChek_orderNumberOfWork = true
    },
    update_purchases (tr) {
      this.purchaseOrderNumber = tr.purchaseOrderNumber
      this.id_purchaseOrderNumber = tr.id
      this.update_produitChek_purchaseOrderNumber = true
    },
    submit_purchases () {
      this.$vs.loading()
      //const purchaseOrderNumber = this.purchaseOrderNumber
  
      let url = 'purchases/'
      let methods = 'post'
      const message = {
        error: this.$t('purchase_error'),
        success: this.$t('purchase_save')
      }
      if (this.id_purchaseOrderNumber) {
        url += `${this.id_purchaseOrderNumber}/`
        methods = 'put'
        message.success = this.$t('purchase_update')
      }
  
      this.$http[methods](url, {purchaseOrderNumber:this.purchaseOrderNumber})
        .then((response) => {
          this.resetData_purchases()
          this.getAllPurchases()
          window.getPrendTaCom.success(message.success, response)
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            if (item === 'purchaseOrderNumber') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('bon_comd_error'),
                color: 'warning',
                position: 'top-left'
              })
            }
            
          }
  
          window.getPrendTaCom.error(message.error)
        })
    },
    submit_orderNumberOfWorks () {
      this.$vs.loading()
      //const orderNumberOfWork = this.orderNumberOfWork
  
      let url = 'orderOfWorks/'
      let methods = 'post'
      const message = {
        error: this.$t('orderOfWork_error'),
        success: this.$t('orderOfWork_save')
      }
      if (this.id_orderNumberOfWork) {
        url += `${this.id_orderNumberOfWork}/`
        methods = 'put'
        message.success = this.$t('orderOfWork_update')
      }
  
      this.$http[methods](url, {orderNumberOfWork : this.orderNumberOfWork})
        .then((response) => {
          this.resetData_orderNumberOfWork()
          this.getAllOrderOfWorks()
          window.getPrendTaCom.success(message.success, response)
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            if (item === 'orderNumberOfWork') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text:this.$t('ord_travail_error'),
                color: 'warning',
                position: 'top-left'
              })
            }
            
          }
  
          window.getPrendTaCom.error(message.error)
        })
    },


    submit_produit () {
      this.produit_submit.length = 0
      const obj = {
        id: this.id_produit++,
        sites: this.site_tab,
        compagnies:this.company_tab,
        tasks: this.task_tab,
        purchase:this.purchases_tab,
        orderOfWorks: this.orderOfWorks_tab
      }
      this.produit_submit = obj
      this.FIND_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
      if (this.itemIndexProduit === -1) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
        this.$vs.notify({
          color:'success',
          icon:'done',
          text:this.$t('add_site_pro'),
          position:'top-right'
        })
        this.validateFormation_intialise()
      } else if (this.itemIndexProduit !== -1) {
        this.$vs.notify({
          title: 'ATTENTION',
          color: 'warning',
          icon:'done',
          time:15000,
          text:this.$t('save_task_add_error'),
          position: 'top-center'
        })
      }

    },
    updateProduit () {
      this.load()
      const obj = {
        id: this.id,
        sites: this.site_tab,
        compagnies: this.company_tab,
        tasks: this.task_tab,
        purchase:this.purchases_tab,
        orderOfWorks: this.orderOfWorks_tab
      }
      this.FIND_ITEM_PRODUIT(this.produits_computed, obj)
      if (this.itemIndexProduit === -1) {
        this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj)
        this.acceptAlert()
        this.resetData()
      } else if (this.itemIndexProduit !== -1) {
        //this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj)
        this.$vs.notify({
          title: 'ATTENTION',
          color: 'warning',
          icon:'done',
          time:15000,
          text:this.$t('save_site_up_error'),
          position: 'top-center'
        })
      }
    },
    resetData () {
      this.id = ''
      this.site_tab = ''
      this.company_tab = ''
      this.task_tab = []
      this.purchases_tab = ''
      this.orderOfWorks_tab = []
      this.update_produitChek = false
    }, 
    deleteProduit (id) {
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:this.$t('site_deleted_liste'),
        position:'top-right'
      })
    
    },
    update_pro_chek (prod) {
      this.id = prod.id
      this.site_tab = prod.sites
      this.company_tab = prod.compagnies
      this.task_tab = prod.tasks
      this.purchases_tab = prod.purchase
      this.orderOfWorks_tab = prod.orderOfWorks
      this.update_produitChek = true
    },
    load () {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    acceptAlert () {
      this.$vs.notify({
        color:'success',
        icon:'edit',
        text:this.$t('up_formation'),
        position:'top-right'
      })
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.unshift(produit)
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.sites.id === produit.sites.id && p.compagnies.id === produit.compagnies.id && p.purchase.id === produit.purchase.id)
    },
    FIND_ITEM_COMPANY (produits, produit) {
      this.itemIndexCompagnie = produits.findIndex((p) => p.compagnies.id === produit.compagnies.id)
    },
    addNewData () {
      this.intialise()
      this.update_produitChek = false
      this.activePrompt1 = true
    },
    Edit (ord) {
      this.intialise()
      this.id_update = ord.id
      this.produits_computed = []
      this.name = ord.name
      this.country = ord.country.id
      this.description = ord.description
      for (let i = 0; i < ord.pessNokia.length; i++) {
        this.pessNokia.push(ord.pessNokia[i].id)
      }
      for (let j = 0; j < ord.rom.length; j++) {
        this.rom.push(ord.rom[j].id)
      }
      for (let y = 0; y < ord.qhsNokia.length; y++) {
        this.qhsNokia.push(ord.qhsNokia[y].id)
      }
      for (let z = 0; z < ord.leadProject.length; z++) {
        this.leadProject.push(ord.leadProject[z].id)
      }
      for (let i = 0; i < ord.companyHasSiteHasTasks.length; i++) {        
        const obj = {
          id: this.id_produit++,
          sites: '',
          compagnies:'',
          purchase:'',
          tasks:[],
          orderOfWorks: []
        }
        obj.id = ord.companyHasSiteHasTasks[i].id
        obj.sites = ord.companyHasSiteHasTasks[i].site
        obj.compagnies = ord.companyHasSiteHasTasks[i].company
        obj.purchase = ord.companyHasSiteHasTasks[i].purchase

        for (let y = 0; y <  ord.companyHasSiteHasTasks[i].tasks.length; y++) {
          obj.tasks.push(ord.companyHasSiteHasTasks[i].tasks[y])
        }
        for (let z = 0; z <  ord.companyHasSiteHasTasks[i].orderOfWorks.length; z++) {
          obj.orderOfWorks.push(ord.companyHasSiteHasTasks[i].orderOfWorks[z])
        }
        
        this.produits_computed.push(obj)
      }
      this.activePrompt2 = true
    },
    Show_data (ord) {
      this.intialise()
      this.id_update = ord.id
      this.produits_computed = []
      this.name = ord.name
      this.country = ord.country.id
      this.description = ord.description
      for (let i = 0; i < ord.pessNokia.length; i++) {
        this.pessNokia.push(ord.pessNokia[i].id)
      }
      for (let j = 0; j < ord.rom.length; j++) {
        this.rom.push(ord.rom[j].id)
      }
      for (let y = 0; y < ord.qhsNokia.length; y++) {
        this.qhsNokia.push(ord.qhsNokia[y].id)
      }
      for (let z = 0; z < ord.leadProject.length; z++) {
        this.leadProject.push(ord.leadProject[z].id)
      }
      for (let i = 0; i < ord.companyHasSiteHasTasks.length; i++) {        
        const obj = {
          id: this.id_produit++,
          sites: '',
          compagnies:'',
          purchase:'',
          tasks:[],
          orderOfWorks: []
        }
        obj.id = ord.companyHasSiteHasTasks[i].id
        obj.sites = ord.companyHasSiteHasTasks[i].site
        obj.compagnies = ord.companyHasSiteHasTasks[i].company
        obj.purchase = ord.companyHasSiteHasTasks[i].purchase

        for (let y = 0; y <  ord.companyHasSiteHasTasks[i].tasks.length; y++) {
          obj.tasks.push(ord.companyHasSiteHasTasks[i].tasks[y])
        }
        for (let z = 0; z <  ord.companyHasSiteHasTasks[i].orderOfWorks.length; z++) {
          obj.orderOfWorks.push(ord.companyHasSiteHasTasks[i].orderOfWorks[z])
        }
        
        this.produits_computed.push(obj)
      }
      this.activePrompt3 = true

    },
    validateFormation_intialise () {
      this.site_tab = ''
      this.company_tab = ''
      this.task_tab = []
      this.purchases_tab = ''
      this.orderOfWorks_tab = []
    },
    intialise () {
      this.$validator.reset()
      this.id_update = ''
      this.country = ''
      this.id_purchaseOrderNumber = ''
      this.purchaseOrderNumber = ''
      this.activePrompt1 = false
      this.qhsNokia = []
      this.pessNokia = []
      this.rom = []
      this.leadProject = []
      this.description = ''
      this.name = ''
      this.produits_computed = []
      this.validateFormation_intialise()
    },
    quit () {
      this.intialise()
      this.activePrompt1 = false
      this.activePrompt2 = false
      this.activePrompt3 = false
      this.$validator.reset()
    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`projects/${id}/`)
            .then(response => {
              this.getAllProjects()
              window.getPrendTaCom.success(this.$t('Suppressionproject'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })
  
    },
    formation_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    submitData () {
      this.recp = []
      const tabIdSites = []
      let produits_store = []
      let tabIdSitesNoDoublons = ''
      let objectTasks = ''
      let objectOrderOfWorks = ''
      const obj = {
        name: '',
        country:'',
        description: '',
        pessNokia: '',
        rom: '',
        qhsNokia: '',
        leadProject: '',
        companyHasSiteHasTasks:''
      }
    
      produits_store = this.produits_computed
      if (produits_store.length) {
        for (let i = 0; i < produits_store.length; i++) {
          if (produits_store[i].sites.id) {
            tabIdSites.push(produits_store[i].sites.id)
          }
        }
        tabIdSitesNoDoublons = [...new Set(tabIdSites)]
        for (let i = 0; i < tabIdSitesNoDoublons.length; i++) {
          const obj2 = {
            site :'',
            companies:[]
          }
          obj2.site = tabIdSitesNoDoublons[i]
          for (let j = 0; j < produits_store.length; j++) {
            const obj1 = {
              company : '',
              purchase : '',
              orderOfWorks:[],
              tasks:[]
            }
            if (tabIdSitesNoDoublons[i] === produits_store[j].sites.id) {           
              obj1.company = produits_store[j].compagnies.id
              obj1.purchase = produits_store[j].purchase.id
              objectTasks = produits_store[j].tasks
              objectOrderOfWorks = produits_store[j].orderOfWorks 
              for (let y = 0; y < objectTasks.length; y++) {
                obj1.tasks.push(objectTasks[y].id)
              }
              for (let z = 0; z < objectOrderOfWorks.length; z++) {
                obj1.orderOfWorks.push(objectOrderOfWorks[z].id)
              }
              obj2.companies.push(obj1)
            }
          } 
        
          this.recp.push(obj2)
        }
      }
      obj.name = this.name
      obj.country = this.country 
      obj.description = this.description
      obj.pessNokia = this.pessNokia
      obj.rom = this.rom
      obj.qhsNokia = this.qhsNokia
      obj.leadProject = this.leadProject
      obj.companyHasSiteHasTasks = this.recp
     
 
      let url = 'projects/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('project_save')
      }
     
      if (this.id_update) {
        url += `${this.id_update}/`
        methods = 'put'
        message.success = this.$t('project_update')
      }
      /* if (produits_store.length !== 0) { */
      this.$vs.loading()
      this.$http[methods](url, obj)
        .then((response) => {
          window.projects.getAllProjects()
          window.getPrendTaCom.success(message.success, response)
          this.quit()
        })
        .catch((error) => { 
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            if (item === 'name') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('name'),
                color: 'warning',
                position: 'top-left'
              })
            }
            if (item === 'description') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('description'),
                position: 'top-left'
              })
            }
            if (item === 'pessNokia') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('pessNokia'),
                color: 'warning',
                position: 'top-left'
              })
            }
            if (item === 'rom') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('rom'),
                color: 'warning',
                position: 'top-left'
              })
            }
            if (item === 'qhsNokia') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('qhsNokia'),
                color: 'warning',
                position: 'top-left'
              })
            }
            if (item === 'leadProject') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text: this.$t('leadProject'),
                color: 'warning',
                position: 'top-left'
              })
            }
          }
          this.$vs.loading.close()

        })
      /*  } else {
        this.$vs.notify({
          color:'warning',
          icon:'info',
          text:'liste d\'assignation vide',
          time:15000,
          position:'top-center'
        }) 
        this.$vs.loading.close()
      } */
    },
    getAllProjects () {
      this.$vs.loading()
      this.$http.get('projects/')
        .then((response) => {
          this.projects = response.data          
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllPessNokias () {
      this.$vs.loading()
      this.$http.get('pessNokias/')
        .then((response) => {
          this.pessNokias = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllQhsNokias () {
      this.$vs.loading()
      this.$http.get('qhsNokias/')
        .then((response) => {
          this.qhsNokias = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllRoms () {
      this.$vs.loading()
      this.$http.get('roms/')
        .then((response) => {
          this.roms = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllLeadProjects () {
      this.$vs.loading()
      this.$http.get('leadProjects/')
        .then((response) => {
          this.leadProjects = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllTasks () {
      this.$vs.loading()
      this.$http.get('tasks/')
        .then((response) => {
          this.tasks_liste = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllCompanies () {
      this.$vs.loading()
      this.$http.get('companies/')
        .then((response) => {
          this.companies_liste = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllSites () {
      this.$vs.loading()
      this.$http.get('sites/')
        .then((response) => {
          this.sites_liste = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllPurchases () {
      this.$vs.loading()
      this.$http.get('purchases/')
        .then((response) => {
          this.purchases_liste = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllOrderOfWorks () {
      this.$vs.loading()
      this.$http.get('orderOfWorks/')
        .then((response) => {
          this.orderOfWorks_liste = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllCountries () {
      this.$vs.loading()
      this.$http.get('nationalities/')
        .then((response) => {
          this.countries = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
   
  },
  created () {
    const user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.role = user_role
    if (this.role === 'room') {
      this.statusRole = true
    } else {
      this.statusRole = false
    }
    window.projects = this
    this.getAllProjects()
    this.getAllCountries()
    this.getAllRoms()
    this.getAllLeadProjects()
    this.getAllQhsNokias()
    this.getAllPessNokias()
    this.getAllTasks()
    this.getAllSites()
    this.getAllCompanies()
    this.getAllPurchases()
    this.getAllOrderOfWorks()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
